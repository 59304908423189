
.goods-info-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .customTable {
        .goods-info {
            display: flex;
            flex-direction: column;
            .info {
                margin-bottom: 10px;
            }
            .img {
                display: flex;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                }
            }

            .goods-name {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-size: 16px;
            }
        }
        .a-link {
            font-size: 16px;
            color: #18B063;
        }
        ::v-deep {
            .el-table__empty-block {
                background-image: url("../../../../../assets/images/student/goods-null-data.png");
                background-repeat: no-repeat;
                background-position: center;
                .el-table__empty-text {
                    margin-top: 150px;
                }
            }
        }
    }
}
